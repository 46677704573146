<template>
    <section>
        <div class="modal-body">
            <p class="is-blue page-title mb-5">Reserve your spot</p>

            <b-tabs v-model="activeTab" position="is-centered" size="is-small" expanded :animated="false">
                <b-tab-item>
                    <b-field label="Name" class="mt-4">
                        <b-input required v-model="fullName"></b-input>
                    </b-field>

                    <b-field label="Email" class="mb-4">
                        <b-input required type="email" v-model="email"></b-input>
                    </b-field>

                    <div v-if="!isEmailValid" class="help is-flex is-align-items-center">
                        <p> <b-icon icon="exclamation-triangle"></b-icon>
                            It seems like there is already a reservation for this email </p>
                    </div>

                    <b-field label="Participants"
                        class="mt-4  is-flex is-align-items-center is-justify-content-space-between">
                        <b-numberinput class="participants-field mb-4" type="is-primary" v-model="participants" min="1"
                            max="1" controls-alignment="right" :editable="false"></b-numberinput>
                    </b-field>


                </b-tab-item>

                <b-tab-item>

                    <b-field label="Date" class="mt-4 mb-5" grouped>
                        <b-button class="calendar-icon mr-2" type="is-primary"><img class="mt-1"
                                src="../../assets/calendar--white.svg" /></b-button>

                        <b-datepicker expanded ref="datepicker" v-model="bookingDate" :mobile-native="false"
                            :min-date="minDate" :max-date="maxDate" :unselectable-days-of-week="[0, 2, 5, 3, 6]">
                            <template v-slot:trigger>
                                <b-input @click="$refs.datepicker.toggle()" expanded readonly :value="humanizedDate" />
                            </template>
                        </b-datepicker>

                    </b-field>

                    <b-field label="Time" class="mt-4 timeslots">
                        <b-radio-button :disabled="isTimeInvalid(time)" v-model="bookingTime"
                            v-for="(time, index) in timeSlots" :key="index" :native-value="time" type="is-primary">{{
                time }}</b-radio-button>

                    </b-field>
                    <div v-if="!bookingDate" class="help is-flex">
                        <p class="is-flex is-align-items-center"> <b-icon icon="info-circle"></b-icon>
                            Choose a date to see the available times </p>
                    </div>

                    <article v-if="typeof remainingSlots === 'number' && remainingSlots > 0"
                        class="message is-danger mt-5">
                        <div class="message-body">
                            Hurry! There are only {{ remainingSlots }} {{ remainingSlots > 1 ? 'seats' : 'seat' }} left
                            for this spot.
                        </div>
                    </article>

                </b-tab-item>
            </b-tabs>

            <b-button v-if="activeTab === 1" class="is-large mb-3" type="is-info is-light" expanded rounded
                @click="activeTab -= 1">
                Previous
            </b-button>

            <b-button v-if="activeTab === 0" class="is-large" expanded rounded type="is-primary" @click="activeTab += 1"
                :disabled="isNextBtnDisabled">
                Next
            </b-button>

            <div v-else>
                <b-button class="is-large" expanded rounded type="is-primary" @click="redirect"
                    :disabled="!isBookingValid && activeTab === 2" :loading="isLoadingBooking">Reserve</b-button>
            </div>

        </div>
    </section>
</template>

<script>
import { db } from '../../firebase';
import { mapState } from 'vuex';
import { UsersStates } from '../../store/Storetypes'
import { dateWithMonthAndDay, convertMinutesToHoursAndMinutes, addDurationToTime } from '../../helpers'
export default {
    props: ["tourData", "fullName", "email"],
    data() {
        //const today = new Date();
        const min = new Date();
        min.setHours(10);
        min.setMinutes(0);
        const max = new Date();
        max.setHours(22);
        max.setMinutes(0);
        return {
            remainingSlots: null,
            groups: [],
            dateWithMonthAndDay,
            convertMinutesToHoursAndMinutes,
            addDurationToTime,
            date: new Date(),
            activeTab: 0,
            maxDate: new Date("Wed May 30 2024 00:00:00 GMT+0100 (Hora de verão da Europa Ocidental)"),
            minDate: new Date("Wed May 28 2024 00:00:00 GMT+0100 (Hora de verão da Europa Ocidental)"),
            minTime: min,
            maxTime: max,
            defaultSharedTimeSlots: ["10:00", "14:00"],
            defaultPrivateTimeSlots: ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"],
            isBookingValid: false,
            isBillingValid: false,
            isLoadingBooking: false,
            bookingDate: null,
            bookingTime: '',
            participants: 1,
            isPrivateTour: true,
            subscribeClientToNewsletter: false,
            nOfPrivateTickets: 1,
            isEmailValid: true
        };
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER]
        }),
        lockedIcon() {
            if (this.isPrivateTour) return require('../../assets/lock-closed--white.svg')
            return require('../../assets/lock--closed.png')
        },
        unlockedIcon() {
            if (!this.isPrivateTour) return require('../../assets/lock-open--white.svg')
            return require('../../assets/lock--opened.png')
        },
        isNextBtnDisabled() {
            if (this.activeTab === 0) {
                this.checkIfEmailIsValid()
                return !this.fullName.length || !this.email.length || !this.isEmailValid
            }
            if (this.activeTab === 1) {
                return !this.isBookingValid
            }
            return false
        },
        guiderPayment() {
            const nOfTickets = Math.ceil(this.participants / 4)
            const sharedTourPayment = (this.tourData.price *
                this.participants).toFixed(2) - (10 * this.participants).toFixed(2)
            const price = this.isPrivateTour ? (this.tourData.guiderPayment * nOfTickets).toFixed(2) : sharedTourPayment
            return Number(price)
        },
        websiteFee() {
            // const nOfTickets = this.participants % 4
            const price = this.isPrivateTour ? (this.privatePrice - this.guiderPayment)
                : (10 * this.participants).toFixed(2)

            return `${price} €`
        },
        bookingValidation() {
            const { bookingTime, bookingDate, participants, fullName, email } = this;
            return {
                bookingTime,
                bookingDate,
                participants,
                fullName,
                email
            };
        },
        dateTime() {
            const { bookingTime, bookingDate } = this;
            return {
                bookingTime,
                bookingDate,
            };
        },
        sharedTimeSlots() {
            return this.tourData?.sharedTimeSlots?.length ? this.tourData?.sharedTimeSlots : this.defaultSharedTimeSlots
        },
        privateTimeSlots() {
            return this.tourData?.privateTimeSlots?.length ? this.tourData?.privateTimeSlots : this.defaultPrivateTimeSlots
        },
        humanizedDate() {
            return this.bookingDate ? this.bookingDate.toDateString() : ''
        },
        privatePrice() {
            const nOfTickets = this.participants / 4
            const nOfPrivateTickets = Math.ceil(nOfTickets)
            this.setNOfPrivateTickets(nOfPrivateTickets)
            return this.tourData?.privatePrice * nOfPrivateTickets
        },
        timeSlots() {
            return this.isPrivateTour ? this.privateTimeSlots : this.sharedTimeSlots
        },
        /*  minDate() {
             const today = new Date();
             return this.isPrivateTour ? new Date(
                 today.getFullYear(),
                 today.getMonth(),
                 today.getDate()
             ) : new Date(
                 today.getFullYear(),
                 today.getMonth(),
                 today.getDate() + 1
             )
         } */
    },
    watch: {
        bookingValidation: {
            handler: function () {
                this.isBookingValid =
                    this.bookingTime &&
                    this.bookingDate &&
                    this.participants !== 0 &&
                    this.fullName.trim().length &&
                    this.email.trim().length && this.isEmailValid
            },
            deep: true,
        },
        dateTime: {
            handler: function () {
                this.findDateTimeSlotGroupInfo()
            },
            deep: true,
        },
        email() {
            this.checkIfEmailIsValid()
        },
        bookingDate(value) {
            let date = new Date(value);
            if (
                value.toLocaleDateString() === date.toLocaleDateString() &&
                new Date().getHours() >= 8
            ) {
                if (new Date().getHours() > 16) {
                    let min = new Date();
                    min.setHours(min.getHours() + 4);
                    min.setMinutes(min.getMinutes());
                    this.minTime = min;
                }
            } else if (
                new Date().getHours() <= 16 ||
                value.toLocaleDateString() !== date.toLocaleDateString()
            ) {
                let min = new Date();
                min.setHours(8);
                min.setMinutes(0);
                this.minTime = min;
            }
        },

    },
    async mounted() {
        this.groups = this.getGroups(this.tourData.id)
    },
    methods: {
        checkIfEmailIsValid() {
            if (!this.tourData.blackListedUsers) this.isEmailValid = true
            if (this.tourData.blackListedUsers.includes(this.email)) this.isEmailValid = false
            else this.isEmailValid = true
        },
        getGroups(activityId) {
            let Tours = [];
            db.firestore()
                .collection("groups")
                .where("activityId", "==", activityId)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        Tours.push({
                            ...doc.data(),
                            id: doc.id,
                        });
                    });
                });
            return Tours
        },
        findDateTimeSlotGroupInfo() {
            if (!this.bookingTime || !this.bookingDate) return
            const bookingDate = this.bookingDate.setHours(0, 0, 0, 0)
            const index = this.groups.findIndex(group => group.date === bookingDate && group.time === this.bookingTime)
            if (index < 0) {
                this.remainingSlots = this.tourData.availableSpots
                return
            }
            this.remainingSlots = this.tourData.availableSpots - this.groups[index].groupSize
        },
        isTimeInvalid(time) {
            // Get the current time
            if (!this.bookingDate) return true
            let dateToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).setHours(0, 0, 0, 0)
            const bookingDate = this.bookingDate.setHours(0, 0, 0, 0)

            if (bookingDate === dateToday) return true

            /*   var currentTime = new Date()
              currentTime.setHours(currentTime.getHours() + 1); // Add 2 hours in the future so we have time to get a driver
  
              const formattedTime = time.split(':')
              const hours = formattedTime[0]
              const minutes = formattedTime[1]
  
              var targetTime = new Date();
              targetTime.setHours(hours, minutes, 0, 0); */


            const index = this.groups.findIndex(group => group.date === bookingDate && group.time === time)
            if (index < 0) return false
            let hasAvailableSlots = false
            if (index >= 0) {
                hasAvailableSlots = (this.tourData.availableSpots - this.groups[index].groupSize) > 0
                console.log(hasAvailableSlots, (this.tourData.availableSpots - this.groups[index].groupSize));
            }
            return !hasAvailableSlots
        },
        setNOfPrivateTickets(nOfTickets) {
            this.nOfPrivateTickets = nOfTickets
        },
        subscribeToNewsletter() {
            //TODO
            console.log('has been subscribed to newsletter');
        },
        async redirect() {
            if (this.subscribeClientToNewsletter) this.subscribeToNewsletter()
            this.isLoadingBooking = true;
            const bookingDate = this.bookingDate.setHours(0, 0, 0, 0)

            let data = {
                metadata: {
                    tourName: this.tourData.title,
                    activityId: this.tourData.id,
                    clientId: this.user.uid,
                    clientName: this.fullName,
                    clientEmail: this.email,
                    meetingPoint: this.tourData.meetingPoint,
                    date: bookingDate,
                    time: this.bookingTime,
                    cancellationPolicy: this.tourData.cancellationPolicy,
                    numOfTickets: this.participants,
                    maxNumOfParticipants: this.tourData?.maxNumOfParticipants,
                    minNumOfParticipants: this.tourData?.minNumOfParticipants
                },
            };


            const updatedEmailsList = this.tourData.blackListedUsers ? [...this.tourData.blackListedUsers, this.email] :
                [this.email]

            await fetch(`${process.env.VUE_APP_API_URL}/activities/free`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {

                        // add email to blacklist
                        db.firestore()
                            .collection("tours")
                            .doc(this.tourData?.id)
                            .update({ blackListedUsers: updatedEmailsList })
                            .then(() => {
                                this.isLoadingBooking = false;
                                window.location.href = 'https://guiders.pt/booking-success';
                            })
                            .catch(() => {
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: "There has been an error reserving your spot. If this error persists please contact support.",
                                    position: "is-bottom",
                                    type: "is-danger",
                                });
                                this.isLoadingBooking = false;
                            });

                    } else {
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: data.message.raw.message || "There has been an error reserving your spot. If this error persists please contact support.",
                            position: "is-bottom",
                            type: "is-danger",
                        });
                        this.isLoadingBooking = false;
                    }
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message:
                            "There has been an error reserving your spot. If this error persists please contact support.",
                        position: "is-bottom",
                        type: "is-danger",
                    });
                    this.isLoadingBooking = false;
                    console.log(error.message);
                    return true;
                });
        },
    },
};
</script>

<style scoped lang="scss">
.help {
    color: #FE6261;
    background-color: #fe62610f;
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: 500;
    margin-top: 1.5rem;
    font-size: 14px;
}

.message-body {
    padding: .75em .55em;
    color: #FE6261 !important;
    border-color: #FE6261 !important;
    background-color: #fe62610f !important;
}

::v-deep {
    .message.is-danger {
        background-color: #fe62610f;
    }
}

.tour--img {
    height: 120px;
    width: -webkit-fill-available;
}

.tour-type--cnt {
    color: #0e3d4d;
    border-radius: 8px;
    padding: 8px 12px;

    .icon {
        height: 1.2rem;
        object-fit: contain;
    }

    &.selected {
        color: #fff;
        background-color: #0e3d4d // box-shadow: 0 0 0 2px #0e3d4d;
    }
}

.page-title {
    font-size: 22px;
}

.message--info {
    padding: 20px;
    border-radius: 10px;
    background-color: #d5ebff33;

    .is-size-6 {
        font-size: 1.05rem !important
    }
}

.button.is-large {
    height: 2.5rem;
    font-size: 1rem;
}

.calendar-icon {
    height: 40px;
    width: 40px;

    img {
        height: 1.25em;
        width: 1.5em;
    }
}

.b-radio.radio.button.is-primary.is-selected {
    background-color: var(--primary);
    color: #fff;
}

.modal .modal-close {
    top: 34px;
}

.field.has-addons .control .button {
    border-bottom-left-radius: 60px;
    border-top-left-radius: 60px;
    border-bottom-right-radius: 60px;
    border-top-right-radius: 60px;
}

.field.has-addons .control .button {
    border-radius: 60px !important;
    font-weight: 500;
}

/*.timeslots{
    .button {
      border: 2px solid #0e3d4d;
  }
  }*/

::v-deep {
    .b-radio.radio[disabled] {
        opacity: 0.3;
        background-color: #e0ebef;
        border-color: transparent;
    }

    .label:not(:last-child) {
        margin-bottom: 1em;
    }

    .field.has-addons .control .button {
        border-radius: 60px !important;
        font-weight: 500;
    }

    .button.is-info.is-light {
        background-color: rgba(213, 235, 255, 0.2)
    }

    .tag:not(body).is-primary.is-light {
        border: 2px solid;
    }

    .tag:not(body).is-light {
        background-color: #f7fbff;
        color: #0e3d4d;
        font-weight: 600;
        font-size: .85rem;

        .icon {
            height: 1rem;
            object-fit: contain;
        }
    }

    .timeslots {
        .control {
            gap: 0.5rem;
            display: grid;
            grid-template-columns: repeat(2, minmax(min-content, 1fr));
        }
    }

    .b-tabs .tab-content {
        padding: 0
    }

    footer.modal-card-foot {
        background-color: white !important;
    }

    footer.modal-card-foot {
        background-color: white !important;
    }

    .timepicker {
        .field.has-addons {
            display: grid;
            grid-template-columns: repeat(3, minmax(max-content, 1fr));
        }
    }

    .b-numberinput input[type=number] {
        width: 40px
    }

    .b-numberinput.field.is-grouped div.control {
        -webkit-box-flex: 0;
        -ms-flex-positive: 1;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .participants-field {
        .input {
            border-color: transparent;
        }

        .control {

            .button.is-primary[disabled],
            .button.is-primary {
                background-color: transparent;
                border-color: transparent;
                color: #0e3d4d
            }

            .button.is-primary[disabled] {
                opacity: .1
            }
        }
    }
}


::v-deep .is-large.delete,
.is-large.modal-close {
    background-color: #0e3d4d !important;
}

.buttons {
    margin-top: 42px;
}

.select select,
.taginput .taginput-container.is-focusable,
.input {
    margin-bottom: 0;
}

.date {

    .select select {
        border-color: transparent;
    }

}

.datepicker .dropdown-item,
.datepicker .dropdown .dropdown-menu .has-link a,
.dropdown .dropdown-menu .has-link .datepicker a {
    font-size: inherit;
    padding: 0;
}

.datepicker .dropdown .input[readonly],
.datepicker .dropdown-trigger .input[readonly] {
    cursor: pointer;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.datepicker .dropdown-content {
    background-color: transparent;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.datepicker .dropdown .input,
.datepicker .dropdown-trigger .input {
    cursor: pointer;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.column.book-column {
    height: 100vh;
}

.booking-container {
    padding: 32px;
    border-radius: 8px;
    background-color: #f9f9f9;
    color: #f9f9f9;
    margin: 95px 0px;
}

.timepicker select {
    background-color: transparent;
}

@media only screen and (max-width: 768px) {

    .dropdown-item,
    .dropdown .dropdown-menu .has-link a {
        padding: 0;
        margin: 24px 0px;
    }
}

.dropdown-item,
.dropdown .dropdown-menu .has-link a {
    margin: 0;
}

.page-footer {
    bottom: 0;
    background-color: whitesmoke;
    width: 100%;
    position: fixed;
    left: 0;
    padding: 20px;
}

.modal-body {
    border-radius: 8px;
    padding: 20px;
    background-color: white;
    width: -webkit-fill-available;
    min-height: auto;
    border: 1px solid #EDEBF2;
}

section .modal-content,
.modal-card {
    max-height: 100%;
}

.level-item {
    align-items: end;
    justify-content: flex-end;
}

.margin {
    margin: 42px 0px;
}

.title.is-4 {
    padding-bottom: 32px;
}
</style>