export const Tours = [
    {
        "reports": [],
        "sharedTimeSlots": [
            "10:00"
        ],
        "cityId": 1,
        "minNumOfParticipants": 4,
        "meetingPoint": "Praça da Figueira, 1100-241 Lisboa",
        "whatToExpect": "This tour is a \"shared tour.\" This means that multiple people will be joining the same tour at the same time. This can be a great way to meet new people and share the experience with others.",
        "additionalInfo": "If you'd like a private tour, for just yourself or your group, you'll need to purchase 6 tickets.",
        "duration": "420",
        "privatePrice": 280,
        "price": 140,
        "languages": [
            "English",
            "Français",
            "Español",
            "Portuguese"
        ],
        "city": "Sintra, Portugal",
        "guiderPayment": 220,
        "stars": null,
        "sharedPriceLink": "price_1PDmFOJou6FLnGKIpF5hQe5S",
        "marketPrice": 350,
        "isVisible": true,
        "whatsIncluded": "",
        "cancellationPolicy": "You may cancel your tour up to 4 days prior to the scheduled tour date without incurring any cancellation fees.",
        "marker": {
            "lng": -9.138160705566406,
            "lat": 38.71364974975586
        },
        "notSuitableFor": "",
        "coverImage": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/sintra-pena-060-65467351c0fae-65467e5a10b62.webp?alt=media&token=4a72fe04-6782-4841-8e00-7bbd4b6c8711",
        "id": "D1YH6p0pVThgornEsYhe",
        "isFeatured": false,
        "evaluations": [],
        "galleryImages": [
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/sintra-pena-028-654673b08f638-65467e6fcd462.webp?alt=media&token=e3df8dac-960c-40eb-8109-35e3faf10f5c",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/sintra-pena-060-65467351c0fae-65467e5a10b62.webp?alt=media&token=61421dc5-d2cb-4141-b254-80ceb4e339e0",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/sintra-regaleira-012-6546736a38148-65467e5c6d5aa.webp?alt=media&token=a9662a8e-1baf-4fd9-b308-c9e2154eecf2",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/sintra-regaleira-002-6546736834d32-65467e5c1bbd8.webp?alt=media&token=06da8476-a60e-461e-9ef7-fdeeb12ae46f",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/sintra-pena-067-6546735643666-65467e5a006d6.webp?alt=media&token=512acb15-339d-41ca-bdf7-f02b94571874",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/sintra-pena-026-65467e6443f45.webp?alt=media&token=4bc611e4-f2c5-4b9c-bee3-433b17e16daa",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/sintra-vila-025-6546737e7c569-65467e5ea10c9.webp?alt=media&token=6b7266d6-5797-463d-8e95-24ef5d276752"
        ],
        "isExternal": false,
        "stops": [
            {
                "title": "Queluz Palace",
                "description": "A splendid 18th-century royal residence known for its stunning Rococo architecture and beautifully landscaped gardens. It served as the summer residence for Portuguese royalty and is often compared to France's Palace of Versailles. "
            },
            {
                "title": "Pena Palace",
                "description": "A striking 19th-century Romanticist castle. Its colorful and eclectic architecture features a mix of Gothic, Moorish, and Renaissance elements. Perched atop a hill, it offers breathtaking views of the surrounding lush forests and the town of Sintra."
            },
            {
                "description": "A captivating estate renowned for its mystical and esoteric symbolism. Designed in the early 20th century, it combines Gothic, Renaissance, and Manueline architectural styles. ",
                "title": "Quinta da Regaleira"
            },
            {
                "description": "The westernmost point of continental Europe and is known for its stunning coastal cliffs that plunge dramatically into the Atlantic Ocean. It's a place of rugged natural beauty, offering breathtaking view and the sensation of standing at the edge of the continent",
                "title": "Cabo da Roca"
            },
            {
                "description": "A dramatic sea cave and natural rock formation carved by the powerful waves of the Atlantic Ocean. Its name translates to \"Hell's Mouth\" due to the roaring sound the waves make as they crash into the cave during storms.",
                "title": "Boca do Inferno"
            },
            {
                "title": "Drop Off --->Praça da Figueira",
                "description": ""
            }
        ],
        "description": "Sintra's history dates back to the Moors, who established a presence in the region in the 8th century. It was later captured by Christian forces in the 12th century. The town's historic center is dominated by the Moorish Castle (Castelo dos Mouros), a medieval fortress perched on a hill with panoramic views of the surrounding landscape.\nSintra's enchanting atmosphere has inspired poets, writers, and artists for centuries, including Lord Byron and Hans Christian Andersen.",
        "accessibility": "",
        "title": "SINTRA Tour - A Guided Journey Through a Fairytale Town",
        "reviews": [
            {
                "stars": 5,
                "review": "My girlfriend and I booked this tour had an incredible experience. Ana was our guide and a big part as to why we loved every second of this tour. She was very personable and shared her extensive knowledge of the history and culture of Portugal and all the places we visited in Sintra. The tour was worth every penny spent and then some!",
                "title": "Thank you Ana",
                "name": "Sam C"
            }
        ],
        "maxNumOfParticipants": 8,
        "priceLink": "price_1PDmFNJou6FLnGKI8LcEgs4H",
        "privateTimeSlots": [
            "10:00"
        ],
        "categories": [
            13
        ]
    },
    {
        "title": "Tuk Tuk-Lisbon's Highlights Tour  An Unforgettable Experience! (3h Tour)",
        "city": "Lisbon, Portugal",
        "isExternal": false,
        "maxNumOfParticipants": 6,
        "priceLink": "price_1PLsVxJou6FLnGKIUFzN3VFX",
        "meetingPoint": "Praça da Figueira, 1100-241 Lisboa",
        "accessibility": "❌ Not wheelchair accessible\n\nIf you have questions about accessibility don't hesitate to contact us at <a class=\"link\" href=\"tel:00351920043375\"> (+351) 920 043 375</a> (this number is also available on WhatsApp)",
        "notSuitableFor": "",
        "sharedPriceLink": "price_1PLsVyJou6FLnGKIUKy7eyik",
        "isVisible": true,
        "reports": [],
        "categories": [
            0
        ],
        "stars": null,
        "cityId": 0,
        "privatePrice": 176,
        "minNumOfParticipants": 4,
        "guiderPayment": 120,
        "evaluations": [],
        "galleryImages": [
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/caption-654673947239b-65467e631fa91.webp?alt=media&token=a24c468f-1a90-4b76-a1db-2438330c3fb5",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/chiado-d-006-654673f3355cd-65467dac6656a.webp?alt=media&token=ee55838e-b2c2-4094-93cb-ea21e47e443e",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/portas-do-sol-d-019-654673107d009-65467e67a8b37.webp?alt=media&token=cf8b1719-107b-4f8b-9b34-185ea329c9db",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/baixa-d-010-654673c75b8bc-65467e6207059.webp?alt=media&token=77a3e90b-c3b2-4cfb-8890-a2784758c0cb",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/monastery-4552556-65467db323bf7.webp?alt=media&token=ae1b14fe-996d-43ff-a5d3-a78d9a688939",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/mosteiro-dos-jeronimos-211209-65467db361dcb.webp?alt=media&token=9442d455-6d65-4c98-9cbf-350f0a26f585",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/tram-4379656-65467e6581976.webp?alt=media&token=18763fd4-788b-4917-8773-aa05a95e7160",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/lisbon-483261-65467dae9b9e3.webp?alt=media&token=a3f9b657-08e1-433c-a27a-09feb925438c",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/1000019968.jpg?alt=media&token=a23993a4-ef79-4078-8d41-d0dd0ab43f79",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/1000019972.jpg?alt=media&token=a89d649f-44c0-4829-bb8e-ace84a46e714",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/1000019970.jpg?alt=media&token=c9716933-7e42-4f2b-8dbf-87ee432e4b4e"
        ],
        "whatToExpect": "✅ For private tours, we offer pick-up and/or drop-off service anywhere in the city. After booking, please message the desired address to <a class=\"link\" href=\"tel:00351920043375\">(+351) 920 043 375</a> (this number is also available on WhatsApp).\n✅ Live Commentary from a Knowledgeable Local Guide\n✅ Private Transport in an Electric Tuk-Tuk\n✅ We will make stops along the way so you can take in the views and take photos",
        "description": "Join us on a journey through Lisbon's iconic landmarks with our Tuk Tuk Tour!\n\nOur first stop is the Lisbon Cathedral, where Gothic architecture meets centuries of history. After that, we will explore the remnants of ancient Roman Theater ruins, a testament to Lisbon's rich past.\n\nFrom there, we will visit the breathtaking Portas do Sol Viewpoint and Senhora do Monte Viewpoint for panoramic views that will leave you speechless and provide an incredible photo op.\n\nNext, we will take you to the Monastery of São Vicente de Fora and the National Pantheon of Lisbon so you can admire their architectural brilliance.\n\nThen, we will continue to Jerónimos Monastery. Afterward, we will pass through the renowned Pasteis de Belém Bakery (we highly recommend trying a Pastel de Belém).\n\nOur adventure will lead us to the Belém Tower and the Monument to the Discoveries, symbols of Portugal's rich maritime heritage. Finally, we will leave you at Commerce Square, the bustling heart of Lisbon, where you will be ideally situated to continue your adventure.",
        "reviews": [
            {
                "stars": 5,
                "name": "Esteban F",
                "title": "Recommend",
                "review": "We got a better sense of what the city has the offer and because of our guide’s suggestions now we know where else we want to go and do. Do this tour, you won't be disappointed"
            },
            {
                "title": "Good time spent",
                "name": "Esteban F",
                "stars": 5,
                "review": "We got a better sense of what the city has the offer and because of our guide’s suggestions now we know where else we want to go and do. Do this tour, you won't be disappointed"
            },
            {
                "name": "Arjun J",
                "stars": 5,
                "title": "Loved the experience",
                "review": "The tour was super informative and we got to see a bunch of the places we had on our list. Recommend."
            },
            {
                "stars": 5,
                "review": "Everyone in my family of 9 loved this tour. Our guides took us around the city and allowed us time to stop and explore some things on our own. It was a great experience to see the city.",
                "title": "I recommend this as a family activity",
                "name": "Tatyana I"
            },
            {
                "title": "Excellent way to see Lisbon",
                "stars": 5,
                "review": "What an excellent way to get to know the beautiful city of Lisbon. Our driver, Marco, was very punctual, knowledgeable and a hardworking young man.",
                "name": "Hanna M"
            },
            {
                "stars": 5,
                "review": "It's a perfect way to explore Lisbon when you don't have too much time or the weather is too warm. ",
                "title": "Perfect way to see almost everything in this VERY hilly city",
                "name": "Lila T"
            },
            {
                "name": "Vittoria R",
                "stars": 5,
                "title": "loved loved loved this tour",
                "review": "We saw the entire city and became very familiar with where we wanted to go and revisit for the rest of our week."
            },
            {
                "name": "Hugo O",
                "title": "Great tour with a local guide",
                "stars": 5,
                "review": "Our guide was very friendly and took a lot of photos for us! He gave very good local recommendations for non touristy restaurants "
            }
        ],
        "price": 88,
        "stops": [
            {
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fse.png?alt=media&token=d02a8b2b-e03a-4143-a0ea-23c199262ec2",
                "description": "The Lisbon Cathedral, also known as Sé de Lisboa, is a historic masterpiece in Portugal. Dating back to the 12th century, it's one of the oldest and most iconic religious buildings in the country.",
                "title": "Lisbon Cathedral"
            },
            {
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Falfama.png?alt=media&token=44708161-9ae9-4ab7-93e4-3f7bbe08a784",
                "title": "Roman Theater Ruins",
                "description": "The Roman Theater Ruins in Lisbon are a remarkable historical site. These well-preserved remains of a Roman theater from the 1st century AD offer insight into the city's ancient past."
            },
            {
                "title": "Portas do Sol",
                "description": "The Miradouro Portas do Sol in Lisbon is a picturesque viewpoint offering stunning vistas of the city. Perched atop the historic Alfama district, it provides panoramic views of the Tagus River and colorful houses that adorn the hills. ",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fportas sol.png?alt=media&token=56e5f7f7-dbfd-4d67-aee9-ad1092fc3dd2"
            },
            {
                "description": "The Senhora do Monte Viewpoint is a hidden gem in Lisbon, Portugal. Nestled in the Graça neighborhood, it offers spectacular panoramic views of the city",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fsenhora monte.png?alt=media&token=ddd579d2-3c03-4118-9433-977cad538211",
                "title": "Senhora do Monte Viewpoint"
            },
            {
                "description": "The Monastery of São Vicente de Fora a remarkable historical and architectural gem. Dating back to the 16th century this monastery is famous for its ornate cloisters, exquisite tilework and the tombs of several Portuguese monarchs.",
                "title": "Monastery of São Vicente de Fora",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fsvfora.png?alt=media&token=33453ae6-1330-435b-8d04-b3bd4a25532a"
            },
            {
                "description": "The National Pantheon of Lisbon, known as the \"Panteão Nacional\" in Portuguese, is a stunning architectural masterpiece and a place of historical importance.Inside, it houses the tombs of several notable Portuguese figures, including famous writers and politicians.",
                "title": "National Pantheon of Lisbon",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fpantheon.png?alt=media&token=ca72e374-23db-45e6-82e0-71456be21128"
            },
            {
                "title": "Alfama",
                "description": "Alfama is one of the oldest and most enchanting neighborhoods in Lisbon. Its winding narrow streets, historic buildings, and vibrant atmosphere capture the essence of the city's soul.",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Falfama.png?alt=media&token=44708161-9ae9-4ab7-93e4-3f7bbe08a784"
            },
            {
                "description": "The Jerónimos Monastery, or Mosteiro dos Jerónimos, is a masterpiece of Manueline architecture in Lisbon, Portugal. This UNESCO World Heritage Site was constructed in the 16th century and features intricate stone carvings, ornate cloisters, and a stunning church. ",
                "title": "Jerónimos Monastery",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20199.png?alt=media&token=fc8c21a6-a875-426c-865b-c6ac6ffba3dd"
            },
            {
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fpasteis.png?alt=media&token=c782d8b8-ae96-432b-949e-a96d753ce510",
                "description": "The \"Pastéis de Belém\" are a delectable Portuguese pastry, renowned for their creamy custard filling and crispy, flaky crust.",
                "title": "Pastéis de Belém bakery"
            },
            {
                "description": "The Belém Tower, or Torre de Belém, is an iconic fortress in Lisbon, Portugal. This striking Manueline-style tower was built in the 16th century and served as a defensive fortification and a symbol of Portugal's maritime power during the Age of Discovery.",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20200.png?alt=media&token=0a18ee2d-3bc3-427e-8449-97d1b73d28c6",
                "title": "Belém Tower"
            },
            {
                "description": "The Padrão dos Descobrimentos, or Monument to the Discoveries, is a prominent landmark in Lisbon, Portugal. This colossal monument, shaped like a caravel ship, celebrates Portugal's Age of Discovery and its explorers. ",
                "title": "Monument to the Discoveries",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fbelem.png?alt=media&token=61310dfb-802d-4e81-b1c4-7247b2c4929d"
            },
            {
                "title": "Drop Off : Commerce Square",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fpcomercio.png?alt=media&token=8eafde97-fb88-4439-823f-410a1b439c55",
                "description": "Praça do Comércio, also known as Commerce Square, is an iconic landmark in Lisbon, Portugal. This magnificent square faces the Tagus River and is bordered by stunning neoclassical buildings."
            }
        ],
        "isFeatured": false,
        "whatsIncluded": "",
        "sharedTimeSlots": [
            "10:00",
            "14:00"
        ],
        "duration": "180",
        "languages": [
            "English",
            "Português",
            "Français",
            "Español"
        ],
        "privateTimeSlots": [
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00"
        ],
        "cancellationPolicy": "For a full refund, cancel at least 48 hours before of the start of the experience.",
        "coverImage": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/1000019970.jpg?alt=media&token=342eaf07-2d69-4f20-91fd-4bdaa2c6680b",
        "id": "FyBrCr0owDZFSWu7mag7",
        "marker": {
            "lng": -9.138160705566406,
            "lat": 38.71364974975586
        },
        "marketPrice": 160,
        "additionalInfo": "This tour isn't recommend for people with back problems. The streets in Lisbon are very bumpy and it will be uncomfortable on your back."
    },
    {
        "privateTimeSlots": [
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00"
        ],
        "stops": [
            {
                "title": "Lisbon Cathedral",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fse.png?alt=media&token=d02a8b2b-e03a-4143-a0ea-23c199262ec2",
                "description": "The Lisbon Cathedral, also known as Sé de Lisboa, is a historic masterpiece in Portugal. Dating back to the 12th century, it's one of the oldest and most iconic religious buildings in the country."
            },
            {
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fromanruins.png?alt=media&token=163953e6-ad67-46f6-8c5c-a9fb033402ce",
                "title": "Roman Theater Ruins",
                "description": "The Roman Theater Ruins in Lisbon are a remarkable historical site. These well-preserved remains of a Roman theater from the 1st century AD offer insight into the city's ancient past."
            },
            {
                "description": "São Jorge Castle is a prominent landmark in Lisbon. This historic fortress offers panoramic views of the city and the Tagus River. Dating back to the medieval period, it features impressive battlements and a central keep. ",
                "title": "São Jorge Castle",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fsjorge.png?alt=media&token=ea8763a8-77fc-4439-aba5-4a682a69ec92"
            },
            {
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fportas sol.png?alt=media&token=56e5f7f7-dbfd-4d67-aee9-ad1092fc3dd2",
                "title": "Portas do Sol",
                "description": "The Miradouro Portas do Sol in Lisbon is a picturesque viewpoint offering stunning vistas of the city. Perched atop the historic Alfama district, it provides panoramic views of the Tagus River and colorful houses that adorn the hills. "
            },
            {
                "title": "Senhora do Monte Viewpoint",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fsenhora monte.png?alt=media&token=ddd579d2-3c03-4118-9433-977cad538211",
                "description": "The Senhora do Monte Viewpoint is a hidden gem in Lisbon, Portugal. Nestled in the Graça neighborhood, it offers spectacular panoramic views of the city"
            },
            {
                "title": "Monastery of São Vicente de Forais",
                "description": "The Monastery of São Vicente de Forais a remarkable historical and architectural gem. Dating back to the 16th century this monastery is famous for its ornate cloisters, exquisite tilework and the tombs of several Portuguese monarchs.",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fsvfora.png?alt=media&token=33453ae6-1330-435b-8d04-b3bd4a25532a"
            },
            {
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fpantheon.png?alt=media&token=ca72e374-23db-45e6-82e0-71456be21128",
                "description": "The National Pantheon of Lisbon, known as the \"Panteão Nacional\" in Portuguese, is a stunning architectural masterpiece and a place of historical importance.Inside, it houses the tombs of several notable Portuguese figures, including famous writers and politicians.",
                "title": " National Pantheon of Lisbon"
            },
            {
                "description": "Alfama is one of the oldest and most enchanting neighborhoods in Lisbon. Its winding narrow streets, historic buildings, and vibrant atmosphere capture the essence of the city's soul.",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Falfama.png?alt=media&token=44708161-9ae9-4ab7-93e4-3f7bbe08a784",
                "title": "Alfama"
            },
            {
                "description": "Praça do Comércio, also known as Commerce Square, is an iconic landmark in Lisbon, Portugal. This magnificent square faces the Tagus River and is bordered by stunning neoclassical buildings.",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fpcomercio.png?alt=media&token=8eafde97-fb88-4439-823f-410a1b439c55",
                "title": "Praça do Comércio"
            },
            {
                "title": "Baixa Chiado",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fbaixachiado.png?alt=media&token=2e2c8f17-0c3f-44c3-a802-0385a5d3b75a",
                "description": "The Chiado neighborhood is one of the most iconic and vibrant districts in Lisbon, Portugal. It's known for its rich cultural heritage, upscale shopping, and a lively atmosphere."
            },
            {
                "title": " Santa Justa Lift",
                "description": "The Santa Justa Lift, an iconic wrought-iron elevator in Lisbon, Portugal, is not just a practical transport system but also a historical landmark. Designed by Raoul Mesnier du Ponsard, it connects the lower Baixa district with the higher Carmo Square. ",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fsantajust.png?alt=media&token=de8a04ec-3bba-4671-81f5-884b05883643"
            },
            {
                "description": "Bairro Alto, Lisbon's bohemian district, is known for its vibrant nightlife, charming streets, and historic architecture. It comes alive at night with numerous bars, restaurants, and Fado houses, making it a popular destination for those seeking entertainment",
                "title": "Bairro Alto",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fbairroalto.png?alt=media&token=44aba2f0-859c-48e4-a6ea-362171ccaf04"
            },
            {
                "description": "Avenida da Liberdade is a renowned boulevard in Lisbon, Portugal. Lined with elegant trees and upscale boutiques, it exudes a sense of luxury. This grand avenue is famous for its historical significance and hosts cultural events throughout the year.",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Favliberdade.png?alt=media&token=b97e143a-6677-451d-bafa-2f244121a872",
                "title": "Avenida da Liberdade"
            },
            {
                "title": "Drop off: Rossio",
                "description": "Rossio Square, officially known as Praça Dom Pedro IV, is a bustling and central square in Lisbon, Portugal. It's characterized by its wave-patterned cobblestone pavement and an impressive bronze statue of Dom Pedro IV. ",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Frossio.png?alt=media&token=4168d0bb-db96-40bd-8a61-f5e47308d7c9"
            }
        ],
        "duration": "120",
        "marketPrice": 120,
        "marker": {
            "lat": 38.71364974975586,
            "lng": -9.138160705566406
        },
        "additionalInfo": "This tour isn't recommend for people with back problems. The streets in Lisbon are very bumpy and it will be uncomfortable on your back.",
        "whatToExpect": "",
        "accessibility": "❌ Not wheelchair accessible\n\nIf you have questions about accessibility don't hesitate to contact us at <a class=\"link\" href=\"tel:00351920043375\"> (+351) 920 043 375</a> (this number is also available on WhatsApp)",
        "isExternal": false,
        "coverImage": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/1000019968.jpg?alt=media&token=dc187d72-1053-4605-9734-778c0b78305e",
        "city": "Lisbon, Portugal",
        "id": "63mFURw6KKq6ZFTmGtMQ",
        "maxNumOfParticipants": 6,
        "evaluations": [],
        "priceLink": "price_1PLsWmJou6FLnGKI2Bp11Grh",
        "reports": [],
        "reviews": [
            {
                "title": "Worth the money to get to know the city",
                "stars": 5,
                "review": "There’s no way we would have seen or learned as much without this tour. It was obvious that Marco loves his city. We were a bit hesitant to book this tour but now we are definitely glad we did!",
                "name": "Giorgio R"
            },
            {
                "review": "There are so many tuk tuks looking for clients on every street of Lisbon but we booked our tour beforehand and I’m so happy we did! Our guide was very knowledgeable and showed us amazing places. We even got Pina coladas made In Pineapples and he let us take them with us on the rest of our trip. A fantastic way to see this beautiful city. ",
                "name": "Greta M",
                "stars": 5,
                "title": "Great experience"
            },
            {
                "title": "Best tour ever",
                "name": "Charlotte S",
                "review": "Did this tour as a part of my hen party.   We had a blast with Marco and Carlos as our tour guides.  They took us to the best viewpoints in the city where we enjoyed live music and an amazing atmosphere. It was the perfect way to spend the day and definitely worth the money we spent.",
                "stars": 5
            },
            {
                "stars": 5,
                "title": "No complaints",
                "review": "Our guide explained everything very thoroughly in both Spanish and English. He was very patient and drove safely.",
                "name": "Carlos R"
            },
            {
                "name": "Isabella S",
                "title": "Best way to see the city without walking",
                "stars": 5,
                "review": "Marco gave us a terrific tour around Lisbon. He was very knowledgeable and took us to some amazing places. We thoroughly enjoyed our trip with him and recommend this tour as a good way to see Lisbon without too much walking."
            },
            {
                "name": "Zara W",
                "review": "We paid €25 each for 2hr. Our guide was knowledgeable, entertaining and spoke great English. He even had a card machine on board so there was no need for cash! I suggest this tour to anyone wanting to really get to know the city",
                "title": "Cheap way to see the city",
                "stars": 5
            },
            {
                "title": "Great for teens",
                "name": "Luca B",
                "review": "Really enjoyed our tuk tuk tour of the city. We stopped at various locations for photos and our guide told us about areas we could visit in our own time. Had 2 teenagers and a child that enjoyed it too. The tuk tuk met us at our hotel. Highly recommend this tour.",
                "stars": 5
            },
            {
                "review": "Our guide, Carlos, showed us all the things that interested us and really listened to what we wanted from the experience. Great experience",
                "title": "Loved this tour! A brilliant way to see Lisbon. ",
                "stars": 5,
                "name": "Betina L"
            },
            {
                "stars": 5,
                "title": "Great tour",
                "name": "Diana H",
                "review": "We had an excellent tour with Carlos. This is our second tour with him, and will not be our last. A great way to introduce friends and family to Lisbon!"
            }
        ],
        "description": "Explore Lisbon's Enchanting Landmarks on Our Tuk Tuk Tour!\n\nJoin us on a captivating adventure through the vibrant streets of Lisbon as our tuk tuk takes you to the city's most iconic landmarks. Start with the awe-inspiring Lisbon Cathedral and Roman Theater Ruins, steeped in centuries of history. Then, ascend to São Jorge Castle for panoramic views. Capture unforgettable moments at Portas do Sol and Senhora do Monte viewpoints. Visit the elegant Monastery of São Vicente de Fora and explore the remarkable National Pantheon. Immerse yourself in the authentic charm of Alfama and admire the grandeur of Praça do Comercio. Experience the lively atmosphere of Baixa-Chiado and ride the Santa Justa Lift. Discover the bohemian vibes of Bairro Alto, stroll along Avenida da Liberdade, and soak in the lively ambiance of Rossio Square. Don't miss this opportunity to uncover the mesmerizing beauty of Lisbon. Book now and create memories to last a lifetime!",
        "price": 60,
        "cityId": 0,
        "minNumOfParticipants": 6,
        "privatePrice": 120,
        "notSuitableFor": "",
        "stars": null,
        "whatsIncluded": "• For private tours, we offer pick-up and/or drop-off service anywhere in the city. After booking, please message the desired address to <a class=\"link\" href=\"tel:00351920043375\">(+351) 920 043 375</a> (this number is also available on WhatsApp).\n• Live Commentary from a Knowledgeable Local Guide\n• Private Transport in an Electric Tuk-Tuk\n• We will make stops along the way so you can take in the views and take photos",
        "guiderPayment": 80,
        "galleryImages": [
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/elevador-de-santa-justa-1046193-1920-65467dae4f14d.webp?alt=media&token=94fff990-004f-4a71-87e2-40f1e2db2d50",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/portugal-4393830-65467db80abca.webp?alt=media&token=d9bcb5bb-7831-4a5e-8909-8827f89e1201",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/monastery-4552556-65467db323bf7.webp?alt=media&token=1ac49302-8fbd-4804-b017-8a6fa7da6526",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/mosteiro-dos-jeronimos-211209-65467db361dcb.webp?alt=media&token=2b1c4034-5739-4a2e-9b04-5ebb8af0f52d",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/monastery-919904-65467db0a81c7.webp?alt=media&token=a7340ecb-79b4-4aa2-9cb7-a2ab34b118ea",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/panteao-21-65467396c892d-65467db76be1d.webp?alt=media&token=7233acbf-e513-47cc-b81d-2a380251948d",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/bairro-alto-d-019-654673b5ea591-65467e60cbfdf.webp?alt=media&token=69361726-1e60-4038-8acd-858f6e30fb7c",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/portas-do-sol-d-024-65467312a1512-65467e695143f.webp?alt=media&token=5ad7380b-592e-4e5b-83ea-82cc569aaafa"
        ],
        "sharedTimeSlots": [
            "10:00",
            "14:00"
        ],
        "isVisible": true,
        "isFeatured": false,
        "languages": [
            "English",
            "Français",
            "Espanõl",
            "Portugues"
        ],
        "title": "TUK TUK  Guided Tour to  Unveiling Lisbon (2 hours Tour)",
        "sharedPriceLink": "price_1PLsWnJou6FLnGKI6I0JhX1g",
        "categories": [
            0,
            3,
            2
        ],
        "meetingPoint": "Praça da Figueira, 1100-241 Lisboa",
        "cancellationPolicy": "For a full refund, cancel at least 48 hours before of the start of the experience."
    },
    {
        "marketPrice": 60,
        "reviews": [
            {
                "name": "Yara A",
                "title": "Amazing Experience!",
                "review": "The tour was fantastic! In just one hour, I got to see so much of this beautiful city. Our guide , Carlos, was very knowledgeable and friendly. I highly recommend this tour to anyone visiting Lisbon!",
                "stars": 5
            },
            {
                "title": "Perfect Introduction to Lisbon",
                "name": "Matthew T",
                "stars": 5,
                "review": "My first time in Lisbon couldn't have started better than with this tuk-tuk tour. It was the perfect way to get acquainted with the city's layout and history. The hour flew by, but we left feeling like we had a good grasp of what Lisbon has to offer. Thanks to our guide for a memorable experience!"
            },
            {
                "name": "Emily J",
                "title": "Short and Sweet",
                "review": "If you're short on time but still want to experience Lisbon, this tour is a must! In just one hour, I saw so many iconic landmarks and learned a lot about the city's history.",
                "stars": 5
            },
            {
                "stars": 5,
                "title": "Must do tour",
                "name": "Michael S",
                "review": "Riding around Lisbon in a tuk-tuk was so much fun! It's a unique way to see the city, and our guide made sure we didn't miss anything important. I loved the flexibility of being able to stop and take photos whenever we wanted. Definitely recommend!"
            },
            {
                "review": "In just one hour, we covered so much ground and got a taste of what makes Lisbon so special. Our guide's enthusiasm was infectious, and I left feeling inspired to explore more of the city.",
                "stars": 5,
                "name": "Ashley W",
                "title": "Great tour"
            },
            {
                "review": "I wasn't sure what to expect from a one hour tour, but I was pleasantly surprised! It was an affordable way to see the touristy spots in Lisbon without breaking the bank.",
                "stars": 5,
                "name": "Sherry C",
                "title": "Great Value for Money"
            },
            {
                "name": "Christopher B",
                "stars": 4,
                "title": "Highlight of My Trip",
                "review": "This tour was definitely the highlight of my trip to Lisbon. I learned so much about the city's history and culture. I only wish it could have been longer! I would suggest anyone interested to book the 2 our 3 hour tour instead :)"
            },
            {
                "stars": 5,
                "name": "Jessica D",
                "review": "Even though it was only an hour long, this tuk-tuk tour left a lasting impression on me. Lisbon is such a beautiful city, and seeing it from a tuk-tuk was a unique experience I won't forget anytime soon. Kudos to our guide Carlos for making it so enjoyable!",
                "title": "Memorable Experience"
            },
            {
                "name": "Sarah A",
                "title": "Good tour",
                "stars": 5,
                "review": "If you're short on time like I was, this tour is the perfect solution. It's a convenient way to see Lisbon's main attractions without spending an entire day on a tour. Our guide was informative and friendly, and I appreciated the flexibility of the team that allowed me to customize the itinerary."
            },
            {
                "review": "This is the best way to get to know Lisbon if you are budgeting. Our guide was excellent, he told us a bunch of interesting facts and answered all of my annoying questions hahaha.",
                "name": "Daniel M",
                "title": "Highly Recommend This Tour",
                "stars": 5
            }
        ],
        "city": "Lisbon, Portugal",
        "isVisible": true,
        "reports": [],
        "categories": [
            0,
            3,
            5
        ],
        "privatePrice": 60,
        "title": "Historic Tour in 1H by Tuk Tuk",
        "description": "Immerse yourself in Lisbon's history and beauty on our captivating tuk tuk tour! From the majestic Lisbon Cathedral to the panoramic views of Miradouro Portas do Sol, the historic Monastery of Sao Vicente de Fora, and the tranquil National Pantheon, our tour will take you on a journey through the city's most iconic landmarks. Experience the breathtaking sights from the Senhora do Monte Viewpoint, explore the vibrant streets of Mouraria, and soak in the lively atmosphere of Figueira Square. Join us for an unforgettable adventure and discover the hidden gems of Lisbon with our expert guides. Book now and create memories to last a lifetime!",
        "cancellationPolicy": "For a full refund, cancel at least 48 hours before of the start of the experience.",
        "marker": {
            "lat": 38.71364974975586,
            "lng": -9.138160705566406
        },
        "galleryImages": [
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/castelo-d-045-654673e38a87a-65467daa62522.webp?alt=media&token=c3473c93-526a-4c19-a389-2b8e0ba9ac84",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/comercio-d-0161-654672de2d427-65467dbaf3896.webp?alt=media&token=4dd7eb34-8b27-46f4-b683-3b6a97c7f6cf",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/alano-oliveira-oj3kmmy-7mu-unsplash-6546737ee3f1c-65467e5eaa919.webp?alt=media&token=a5e5bd06-eff4-402d-b7f5-2986a5cb6697",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/chiado-d-006-654673f3355cd-65467dac6656a.webp?alt=media&token=99bff156-77be-44f5-af1c-827ac3d46dd2",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/castelo-d-035-654673db7ddc3-65467da9b520e.webp?alt=media&token=5aaf0ee7-57fa-4226-a83c-4b4287080cc6",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/IMG_20240210_172833.jpg?alt=media&token=59080d6d-611e-418e-ac37-7ff8eaf4ca86",
            "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/IMG-20240314-WA0000.jpg?alt=media&token=428eccc6-55d5-48ab-8d94-8be8779ce3f9"
        ],
        "languages": [
            "English",
            "Français",
            "Português",
            "Español"
        ],
        "whatToExpect": "",
        "cityId": "0",
        "coverImage": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/victor-malyushev-PssRgE5f_n4-unsplash.jpg?alt=media&token=06e55f7c-c514-4be9-bd5b-6f39b844c60d",
        "isExternal": false,
        "sharedTimeSlots": [
            "10:00",
            "14:00"
        ],
        "additionalInfo": "This tour isn't recommend for people with back problems. The streets in Lisbon are very bumpy and it will be uncomfortable on your back.",
        "stars": null,
        "price": 30,
        "guiderPayment": 40,
        "minNumOfParticipants": 4,
        "priceLink": "price_1PHEhxJou6FLnGKIhkmXpUa3",
        "id": "t78nJPOAbsW4DuwDnZa8",
        "duration": "60",
        "whatsIncluded": "✅ Live Commentary from a Knowledgeable Local Guide <br>\n✅ Private Transport in an Electric Tuk-Tuk<br>\n✅ We will make stops along the way so you can take in the views and take photos",
        "maxNumOfParticipants": 6,
        "isFeatured": false,
        "privateTimeSlots": [
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00"
        ],
        "meetingPoint": "Praça da Figueira, 1100-241 Lisboa",
        "sharedPriceLink": "price_1PHEhyJou6FLnGKIw6jIKGhF",
        "accessibility": "❌ Not wheelchair accessible\n\nIf you have questions about accessibility don't hesitate to contact us at <a class=\"link\" href=\"tel:00351920043375\"> (+351) 920 043 375</a> (this number is also available on WhatsApp)",
        "notSuitableFor": "❌ Pregnant people\n❌ People with back problems",
        "stops": [
            {
                "description": "The Lisbon Cathedral, also known as Sé de Lisboa, is a historic masterpiece in Portugal. Dating back to the 12th century, it's one of the oldest and most iconic religious buildings in the country.",
                "title": "The Lisbon Cathedral",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fse.png?alt=media&token=d02a8b2b-e03a-4143-a0ea-23c199262ec2"
            },
            {
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fportas sol.png?alt=media&token=56e5f7f7-dbfd-4d67-aee9-ad1092fc3dd2",
                "description": "The Miradouro Portas do Sol in Lisbon is a picturesque viewpoint offering stunning vistas of the city. Perched atop the historic Alfama district, it provides panoramic views of the Tagus River and colorful houses that adorn the hills. ",
                "title": "Miradouro Portas do Sol"
            },
            {
                "description": "The Monastery of São Vicente de Fora a remarkable historical and architectural gem. Dating back to the 16th century this monastery is famous for its ornate cloisters, exquisite tilework and the tombs of several Portuguese monarchs.",
                "title": "The Monastery of São Vicente de Fora",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fsvfora.png?alt=media&token=33453ae6-1330-435b-8d04-b3bd4a25532a"
            },
            {
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fpantheon.png?alt=media&token=ca72e374-23db-45e6-82e0-71456be21128",
                "title": "National Pantheon of Lisbon",
                "description": "The National Pantheon of Lisbon, known as the \"Panteão Nacional\" in Portuguese, is a stunning architectural masterpiece and a place of historical importance.Inside, it houses the tombs of several notable Portuguese figures, including famous writers and politicians."
            },
            {
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fsenhora monte.png?alt=media&token=ddd579d2-3c03-4118-9433-977cad538211",
                "description": "The Senhora do Monte Viewpoint is a hidden gem in Lisbon, Portugal. Nestled in the Graça neighborhood, it offers spectacular panoramic views of the city",
                "title": " Senhora do Monte"
            },
            {
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fmouraria1.png?alt=media&token=ad6035f6-cb8d-4f34-9947-9256dfada3c1",
                "title": "Mouraria",
                "description": "Mouraria neighborhood in Lisbon is a vibrant and historic district with a rich cultural heritage. It's known for its narrow, winding streets, colorful facades, and a strong sense of multiculturalism. Mouraria is famous for being the birthplace of Fado music."
            },
            {
                "title": "Drop Off: Figueira Square",
                "description": " Praça da Figueira in Lisbon is a bustling and iconic square located at the heart of the city. It's known for its open space, often hosting markets and events. The square is surrounded by historic buildings and is within walking distance of many of Lisbon's attractions",
                "imageUrl": "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Ffigueira.png?alt=media&token=c408d0f9-e7ab-4d1b-995c-40decf2487d1"
            }
        ]
    }
]