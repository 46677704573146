<template>
  <section>
    <div class="modal-body">
      <p class="is-blue page-title mb-5">Reserve your activity</p>

      <!--  <p class="has-text-success mb-4"><b-icon icon="lock"></b-icon><strong class="has-text-success">Safe and
          secure</strong> payment processing guaranteed</p> -->

      <b-tabs
        v-model="activeTab"
        position="is-centered"
        size="is-small"
        expanded
        :animated="false"
      >
        <b-tab-item>
          <b-field label="Name" class="mt-4">
            <b-input required v-model="fullName"></b-input>
          </b-field>

          <b-field label="Email" class="mb-4">
            <b-input required type="email" v-model="email"></b-input>
          </b-field>
          <b-field
            label="What type of experience do you want?"
            class="mb-4"
            v-if="!tourData.isPrivateOnly"
          >
            <div class="columns is-mobile">
              <div class="column is-6">
                <div
                  class="tour-type--cnt"
                  @click="isPrivateTour = true"
                  :class="{ selected: isPrivateTour }"
                >
                  <div class="is-flex is-align-items-center">
                    <img :src="lockedIcon" class="mr-1 icon" />
                    <b>Private</b>
                  </div>
                </div>
              </div>
              <div class="column">
                <div
                  class="tour-type--cnt"
                  @click="isPrivateTour = false"
                  :class="{ selected: !isPrivateTour }"
                >
                  <div class="is-flex is-align-items-center">
                    <img :src="unlockedIcon" class="mr-1 icon" />
                    <b>Shared</b
                    ><b-tooltip
                      position="is-left"
                      multilined
                      label="Split the cost with other participants and meet people"
                    >
                      <img
                        src="../../assets/info-circle.png"
                        class="icon is-flex ml-1"
                      />
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </b-field>
        </b-tab-item>

        <b-tab-item>
          <!--      <b-field label="Date" class="mt-4 date" >

            <b-datepicker inline  ref="datepicker" v-model="bookingDate" :min-date="minDate"
              :max-date="maxDate">
            </b-datepicker>

          </b-field> 

           <b-field label="Date" class="mt-4" grouped>
              <b-button class="calendar-icon mr-2" type="is-primary"><img class="mt-1"
                   src="../../assets/calendar--white.svg" /></b-button> 
                   <b-datepicker expanded  ref="datepicker" v-model="bookingDate" :min-date="minDate"
                   :max-date="maxDate">
   
                <template v-slot:trigger>
                   <b-input @click="$refs.datepicker.toggle()" expanded readonly :value="humanizedDate" />
                 </template>
</b-datepicker>

</b-field>-->

          <b-field label="Date" class="mt-4 mb-5" grouped>
            <b-button class="calendar-icon mr-2" type="is-primary"
              ><img class="mt-1" src="../../assets/calendar--white.svg"
            /></b-button>

            <b-datepicker
              expanded
              ref="datepicker"
              v-model="bookingDate"
              :mobile-native="false"
              :min-date="tourData.isPrivateOnly ? dateTomorrow :minDate"
              :max-date="maxDate"
            >
              <template v-slot:trigger>
                <b-input
                  @click="$refs.datepicker.toggle()"
                  expanded
                  readonly
                  :value="humanizedDate"
                />
              </template>
            </b-datepicker>
          </b-field>

          <b-field label="Time" class="mt-4 timeslots">
            <b-radio-button
              :disabled="isTimeValid(time)"
              v-model="bookingTime"
              v-for="(time, index) in timeSlots"
              :key="index"
              :native-value="time"
              type="is-primary"
              >{{ time }}</b-radio-button
            >
          </b-field>
          <div v-if="!bookingDate" class="help is-flex">
            <p class="is-flex is-align-items-center">
              <b-icon icon="info-circle"></b-icon> Choose a date to see the
              available times
            </p>
          </div>
          <div v-else class="info is-flex">
            <p>
              <a class='link' href='mailto:support@guiders.pt'>Email us</a> or <a class='link' href='https://wa.me/351920043375' target='_blank'>send a WhatsApp message</a>
              if you would like to schedule the tour at a different time than
              those available or if your group is larger than the accepted
              limit.
            </p>
          </div>

          <b-field
            label="Participants"
            class="mt-4 is-flex is-align-items-center is-justify-content-space-between"
          >
            <b-numberinput
              class="participants-field mb-4"
              type="is-primary"
              v-model="participants"
              min="1"
              :max="tourData.maxNumOfParticipants"
              controls-alignment="right"
              :editable="false"
            ></b-numberinput>
          </b-field>
        </b-tab-item>

        <b-tab-item :disabled="!isBookingValid">
          <div class="columns is-mobile mt-5 mb-5">
            <!--  <div class="column pr-1 is-narrow">
              <img class="is-rounded tour--img" :src="tourData.coverImage" />
            </div> -->

            <div class="column">
              <p class="is-size-5 mt-2 has-text-weight-bold">
                {{ tourData.title }}
              </p>

              <div class="mt-2 is-flex tags">
                <div class="is-flex is-align-items-center mr-4">
                  <img src="../../assets/Calendar.svg" class="icon mr-1" />{{
                    dateWithMonthAndDay(bookingDate)
                  }}
                </div>

                <div class="is-flex is-align-items-center">
                  <img src="../../assets/time-circle.png" class="icon mr-1" />{{
                    bookingTime
                  }}
                </div>
              </div>
            </div>
          </div>

          <p class="is-size-5 mb-4 mt-1 has-text-weight-bold">Order details:</p>
          <div class="display-flex-space-btw mb-2">
            <b>Price to pay to the guide:</b>
            <p>{{ guiderPayment }} €</p>
          </div>

          <div class="display-flex-space-btw mb-4">
            <div class="is-flex is-align-items-center">
              <b>Price to pay now:</b>
            </div>

            <p>{{ websiteFee }}</p>
          </div>

          <div class="separator mt-5 mb-5"></div>

          <div class="display-flex-space-btw">
            <b>Total:</b>
            <p>
              {{
                tourData.privatePrice.toFixed(2)
              }}
              €
            </p>
          </div>
        </b-tab-item>
      </b-tabs>
      <b-button
        v-if="activeTab === 1 || activeTab === 2"
        class="is-large mb-3"
        type="is-info is-light"
        expanded
        rounded
        @click="activeTab -= 1"
      >
        Previous
      </b-button>

      <b-button
        v-if="activeTab === 0 || activeTab === 1"
        class="is-large"
        expanded
        rounded
        type="is-primary"
        @click="activeTab += 1"
        :disabled="isNextBtnDisabled"
      >
        Next
      </b-button>

      <div v-else>
        <!--  <b-button class="is-large" expanded rounded type="is-primary" @click="redirect"
          :disabled="!isBookingValid && activeTab === 2" :loading="isLoadingBooking">Book for {{ isPrivateTour ?
        privatePrice.toFixed(2) : (tourData.price *
          participants).toFixed(2) }} €</b-button> -->
        <b-button
          class="is-large"
          expanded
          rounded
          type="is-primary"
          @click="redirect"
          :disabled="!isBookingValid && activeTab === 2"
          :loading="isLoadingBooking"
          >Reserve for {{ websiteFee }}</b-button
        >
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { UsersStates } from "../../store/Storetypes";
import {
  dateWithMonthAndDay,
  convertMinutesToHoursAndMinutes,
  addDurationToTime,
} from "../../helpers";
export default {
  props: ["tourData", "fullName", "email"],
  data() {
    const today = new Date();
    const min = new Date();
    min.setHours(10);
    min.setMinutes(0);
    const max = new Date();
    max.setHours(22);
    max.setMinutes(0);
    return {
      dateWithMonthAndDay,
      convertMinutesToHoursAndMinutes,
      addDurationToTime,
      dateTomorrow: new Date(today.getTime() + 24 * 60 * 60 * 1000),
      date: new Date(),
      activeTab: 0,
      maxDate: new Date(
        today.getFullYear(),
        today.getMonth() + 3,
        today.getDate()
      ),
      minTime: min,
      maxTime: max,
      defaultSharedTimeSlots: ["10:00", "14:00"],
      defaultPrivateTimeSlots: [
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
      ],
      isBookingValid: false,
      isBillingValid: false,
      isLoadingBooking: false,
      bookingDate: null,
      bookingTime: "",
      participants: 1,
      isPrivateTour: true,
      subscribeClientToNewsletter: false,
      nOfPrivateTickets: 1,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.users[UsersStates.USER],
    }),
    lockedIcon() {
      if (this.isPrivateTour)
        return require("../../assets/lock-closed--white.svg");
      return require("../../assets/lock--closed.png");
    },
    unlockedIcon() {
      if (!this.isPrivateTour)
        return require("../../assets/lock-open--white.svg");
      return require("../../assets/lock--opened.png");
    },
    isNextBtnDisabled() {
      if (this.activeTab === 0) {
        return !this.fullName.length || !this.email.length;
      }
      if (this.activeTab === 1) {
        return !this.isBookingValid;
      }
      return false;
    },
    guiderPayment() {
      /* const nOfTickets = Math.ceil(this.participants / 4)
      const sharedTourPayment = (this.tourData.price *
        this.participants).toFixed(2) - (10 * this.participants).toFixed(2)
      const price = this.isPrivateTour ? (this.tourData.guiderPayment * nOfTickets).toFixed(2) : sharedTourPayment
      return Number(price) */

    /*   const nOfTickets = Math.ceil(this.participants / 4);
      const sharedTourPayment =
        (this.tourData.price * this.participants).toFixed(2) -
        (10 * this.participants).toFixed(2);

      if (this.isPrivateTour) {
        if (this.tourData.isPrivateOnly) {
          return this.privatePrice - 20;
        }
        if (this.participants === 5) {
          return Number(this.tourData.guiderPayment + 7.5).toFixed(2);
        }

        if (this.participants === 6) {
          return Number(this.tourData.guiderPayment + 15).toFixed(2);
        }

        return Number(this.tourData.guiderPayment * nOfTickets).toFixed(2);
      }

      return Number(sharedTourPayment); */
      const price = this.tourData.privatePrice - 20
      return Number(price).toFixed(2);
    },
    websiteFee() {
      // const nOfTickets = this.participants % 4
      /* const price = this.isPrivateTour
        ? (this.privatePrice - this.guiderPayment).toFixed(2)
        : (10 * this.participants).toFixed(2);
      if (this.tourData.isPrivateOnly) {
        return `20 €`;
      } */
      const price = 20
      return `${price} €`;
    },
    bookingValidation() {
      const { bookingTime, bookingDate, participants, fullName, email } = this;
      return {
        bookingTime,
        bookingDate,
        participants,
        fullName,
        email,
      };
    },
    sharedTimeSlots() {
      return this.tourData?.sharedTimeSlots?.length
        ? this.tourData?.sharedTimeSlots
        : this.defaultSharedTimeSlots;
    },
    privateTimeSlots() {
      return this.tourData?.privateTimeSlots?.length
        ? this.tourData?.privateTimeSlots
        : this.defaultPrivateTimeSlots;
    },
    humanizedDate() {
      return this.bookingDate ? this.bookingDate.toDateString() : "";
    },
    privatePrice() {
      /* const nOfTickets = this.participants / 4
      const nOfPrivateTickets = Math.ceil(nOfTickets)
      this.setNOfPrivateTickets(nOfPrivateTickets)
      return this.tourData?.privatePrice * nOfPrivateTickets
   */
      const nOfTickets = this.participants / 4;
      const nOfPrivateTickets = Math.ceil(nOfTickets);
      this.setNOfPrivateTickets(nOfPrivateTickets);
      let totalPrice = this.tourData?.privatePrice;

      if (
        this.tourData.isPrivateOnly &&
        this.participants > 4 &&
        this.participants <= 8
      ) {
        totalPrice = this.tourData.price;
        return totalPrice;
      }

      if (this.participants === 5) {
        totalPrice += 15;
        return totalPrice;
      }
      if (this.participants === 6) {
        totalPrice += 30;
        return totalPrice;
      }
      return this.tourData?.privatePrice * nOfPrivateTickets;
    },
    timeSlots() {
      return this.isPrivateTour ? this.privateTimeSlots : this.sharedTimeSlots;
    },
    minDate() {
      const today = new Date();
      return this.isPrivateTour
        ? new Date(today.getFullYear(), today.getMonth(), today.getDate())
        : new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    },
  },
  watch: {
    bookingValidation: {
      handler: function () {
        this.isBookingValid =
          this.bookingTime &&
          this.bookingDate &&
          this.participants !== 0 &&
          this.fullName.trim().length &&
          this.email.trim().length;
      },
      deep: true,
    },

    bookingDate(value) {
      let date = new Date(value);
      if (
        value.toLocaleDateString() === date.toLocaleDateString() &&
        new Date().getHours() >= 8
      ) {
        if (new Date().getHours() > 16) {
          let min = new Date();
          min.setHours(min.getHours() + 4);
          min.setMinutes(min.getMinutes());
          this.minTime = min;
        }
      } else if (
        new Date().getHours() <= 16 ||
        value.toLocaleDateString() !== date.toLocaleDateString()
      ) {
        let min = new Date();
        min.setHours(8);
        min.setMinutes(0);
        this.minTime = min;
      }
    },
  },

  methods: {
    isTimeValid(time) {
      // Get the current time
      // var currentTime = new Date().setHours(19 + 2, 0, 0, 0);
      if (!this.bookingDate) return true;
      let dateToday = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );

      if (this.bookingDate > dateToday) return false;
      var currentTime = new Date();
      currentTime.setHours(currentTime.getHours() + 1); // Add 2 hours in the future so we have time to get a driver

      const formattedTime = time.split(":");
      const hours = formattedTime[0];
      const minutes = formattedTime[1];

      var targetTime = new Date();
      targetTime.setHours(hours, minutes, 0, 0);

      return currentTime > targetTime;
    },
    setNOfPrivateTickets(nOfTickets) {
      this.nOfPrivateTickets = nOfTickets;
    },
    subscribeToNewsletter() {
      //TODO
      console.log("has been subscribed to newsletter");
    },
    async redirect() {
      if (this.subscribeClientToNewsletter) this.subscribeToNewsletter();
      this.isLoadingBooking = true;
      const duration = convertMinutesToHoursAndMinutes(this.tourData.duration);
      const endTime = addDurationToTime(
        this.bookingTime,
        duration.hours,
        duration.minutes
      );

      const bookingDate = this.bookingDate.setHours(0, 0, 0, 0);
     /*  const privateOnlyIds = ["D1YH6p0pVThgornEsYhe"];
      const isPrivateOnly = privateOnlyIds.includes(this.tourData.id);
      let priceLink= null
      if (isPrivateOnly) {
        priceLink=  'price_1PUs9AJou6FLnGKIs07dcy3t'
      } */
      let data = {
        cancelUrl: `https://guiders.pt/details/${this.$route.params.id}`,
       /*  priceLink: priceLink ? priceLink : ( !this.isPrivateTour
          ? this.tourData.sharedPriceLink
          : this.tourData.priceLink), */
          priceLink:this.tourData.priceLink,
          /*  !this.isPrivateTour
          ? this.tourData.sharedPriceLink
          : this.tourData.priceLink, */
        quantity:  this.tourData.isPrivateOnly ? 1 : (this.isPrivateTour
          ? this.nOfPrivateTickets
          : this.participants),
        metadata: {
          tourName: this.tourData.title,
          activityId: this.tourData.id,
          clientId: this.user.uid,
          clientName: this.fullName,
          clientEmail: this.email,
          meetingPoint: this.tourData.meetingPoint,
          date: bookingDate,
          time: this.bookingTime,
          endTime,
          duration: this.tourData.duration,
          cancellationPolicy: this.tourData.cancellationPolicy,
          numOfTickets: this.participants,
          maxNumOfParticipants: this.tourData?.maxNumOfParticipants,
          minNumOfParticipants: this.tourData?.minNumOfParticipants,
          isPrivateTour: this.isPrivateTour,
          remainingPayment: this.guiderPayment,
        },
      };

      await fetch(`${process.env.VUE_APP_API_URL}/stripe/session`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.isLoadingBooking = false;
           window.location.href = data.message.url;
          } else {
            this.$buefy.toast.open({
              duration: 10000,
              message:
                data.message.raw.message ||
                "There has been an error booking your tour. If this error persists please contact support.",
              position: "is-bottom",
              type: "is-danger",
            });
            this.isLoadingBooking = false;
          }
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 10000,
            message:
              "There has been an error booking your tour. If this error persists please contact support.",
            position: "is-bottom",
            type: "is-danger",
          });
          this.isLoadingBooking = false;
          console.log(error.message);
          return true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.help,
.info {
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 500;
  margin-top: 1.5rem;
  font-size: 14px;
}

.help {
  color: #fe6261;
  background-color: #fe62610f;
}

.info {
  color: #446df6;
  background-color: #ecf3ff;
  padding: 13px 10px;
  border-radius: 10px;
  .link {
    color: #446df6;
  }
}

.tour--img {
  height: 120px;
  width: -webkit-fill-available;
}

.tour-type--cnt {
  color: #0e3d4d;
  border-radius: 8px;
  padding: 8px 12px;

  .icon {
    height: 1.2rem;
    object-fit: contain;
  }

  &.selected {
    color: #fff;
    background-color: #0e3d4d; // box-shadow: 0 0 0 2px #0e3d4d;
  }
}

.page-title {
  font-size: 22px;
}

.message--info {
  padding: 20px;
  border-radius: 10px;
  background-color: #d5ebff33;

  .is-size-6 {
    font-size: 1.05rem !important;
  }
}

.button.is-large {
  height: 2.5rem;
  font-size: 1rem;
}

.calendar-icon {
  height: 40px;
  width: 40px;

  img {
    height: 1.25em;
    width: 1.5em;
  }
}

.b-radio.radio.button.is-primary.is-selected {
  background-color: var(--primary);
  color: #fff;
}

.modal .modal-close {
  top: 34px;
}

.field.has-addons .control .button {
  border-bottom-left-radius: 60px;
  border-top-left-radius: 60px;
  border-bottom-right-radius: 60px;
  border-top-right-radius: 60px;
}

.field.has-addons .control .button {
  border-radius: 60px !important;
  font-weight: 500;
}

/*.timeslots{
  .button {
    border: 2px solid #0e3d4d;
}
}*/

::v-deep {
  .b-radio.radio[disabled] {
    opacity: 0.3;
    background-color: #e0ebef;
    border-color: transparent;
  }

  .label:not(:last-child) {
    margin-bottom: 1em;
  }

  .field.has-addons .control .button {
    border-radius: 60px !important;
    font-weight: 500;
  }

  .button.is-info.is-light {
    background-color: rgba(213, 235, 255, 0.2);
  }

  .tag:not(body).is-light {
    background-color: #f7fbff;
    color: #0e3d4d;
    font-weight: 600;
    font-size: 0.85rem;

    .icon {
      height: 1rem;
      object-fit: contain;
    }
  }

  .tags {
    color: #0e3d4d;
    font-weight: 600;
    font-size: 0.85rem;

    .icon {
      height: 1rem;
      object-fit: contain;
    }
  }

  .timeslots {
    .field.has-addons {
      gap: 0.5rem;
      display: grid;
      grid-template-columns: repeat(4, minmax(min-content, 1fr));
    }
  }

  .b-tabs .tab-content {
    padding: 0;
  }

  footer.modal-card-foot {
    background-color: white !important;
  }

  footer.modal-card-foot {
    background-color: white !important;
  }

  .timepicker {
    .field.has-addons {
      display: grid;
      grid-template-columns: repeat(3, minmax(max-content, 1fr));
    }
  }

  .b-numberinput input[type="number"] {
    width: 40px;
  }

  .b-numberinput.field.is-grouped div.control {
    -webkit-box-flex: 0;
    -ms-flex-positive: 1;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .participants-field {
    .input {
      border-color: transparent;
    }

    .control {
      .button.is-primary[disabled],
      .button.is-primary {
        background-color: transparent;
        border-color: transparent;
        color: #0e3d4d;
      }

      .button.is-primary[disabled] {
        opacity: 0.1;
      }
    }
  }
}

::v-deep .is-large.delete,
.is-large.modal-close {
  background-color: #0e3d4d !important;
}

.buttons {
  margin-top: 42px;
}

.select select,
.taginput .taginput-container.is-focusable,
.input {
  margin-bottom: 0;
}

.date {
  .select select {
    border-color: transparent;
  }
}

.datepicker .dropdown-item,
.datepicker .dropdown .dropdown-menu .has-link a,
.dropdown .dropdown-menu .has-link .datepicker a {
  font-size: inherit;
  padding: 0;
}

.datepicker .dropdown .input[readonly],
.datepicker .dropdown-trigger .input[readonly] {
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.datepicker .dropdown-content {
  background-color: transparent;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.datepicker .dropdown .input,
.datepicker .dropdown-trigger .input {
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.column.book-column {
  height: 100vh;
}

.booking-container {
  padding: 32px;
  border-radius: 8px;
  background-color: #f9f9f9;
  color: #f9f9f9;
  margin: 95px 0px;
}

.timepicker select {
  background-color: transparent;
}

@media only screen and (max-width: 768px) {
  .dropdown-item,
  .dropdown .dropdown-menu .has-link a {
    padding: 0;
    margin: 24px 0px;
  }
}

.dropdown-item,
.dropdown .dropdown-menu .has-link a {
  margin: 0;
}

.page-footer {
  bottom: 0;
  background-color: whitesmoke;
  width: 100%;
  position: fixed;
  left: 0;
  padding: 20px;
}

.modal-body {
  border-radius: 8px;
  padding: 20px;
  background-color: white;
  width: -webkit-fill-available;
  min-height: auto;
  border: 1px solid #edebf2;
  &::-webkit-scrollbar {
    display: none;
}
}

section .modal-content,
.modal-card {
  max-height: 100%;
}

.level-item {
  align-items: end;
  justify-content: flex-end;
}

.margin {
  margin: 42px 0px;
}

.title.is-4 {
  padding-bottom: 32px;
}
</style>
