<template>
  <div class="gallery">
    <!-- Gallery grid with two columns, second column has 3 rows -->
    <div class="grid-container">
      <div v-for="(image, index) in Images" :key="index" class="gallery-item" @click="openImage(index)">
        <img :src="image" :alt="image" />

        <div v-if="index === 3 && images.length > 4" class="remaining-count">
          See all
        </div>
      </div>
    </div>

    <!-- Lightbox overlay with next and previous navigation -->
    <div v-if="showLightbox" class="lightbox" @click="closeLightbox">
      <div class="lightbox-content" @click.stop>
        <img :src="currentImage" :alt="currentImage" />

        <!-- Next/Previous buttons -->
        <button class="prev-button" @click="previousImage">&#10094;</button>
        <button class="next-button" @click="nextImage">&#10095;</button>

        <!-- Close button 
        <b-button  class="close-button" @click="closeLightbox" icon-left="times" />-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
      images: {
        type: Array,
        default: () => []
      },
    },
  data() {
    return {
      showLightbox: false,
      currentImageIndex: null,
      placeholderImgs:[
        "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png",
        "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png",
        "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png",
        "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png",
      ]
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    },
    Images(){
      let images = this.images.length ? this.images.slice(0, 4) : this.placeholderImgs
      return images
    }
  },
  methods: {
    openImage(index) {
      this.currentImageIndex = index;
      this.showLightbox = true;
    },
    closeLightbox() {
      this.showLightbox = false;
    },
    nextImage() {
      if (this.currentImageIndex < this.images.length - 1) {
        this.currentImageIndex++;
      } else {
        this.currentImageIndex = 0; // Loop back to the first image
      }
    },
    previousImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      } else {
        this.currentImageIndex = this.images.length - 1; // Loop back to the last image
      }
    },
  },
};
</script>

<style scoped>
.gallery-item {
  position: relative; /* Position relative to place the overlay */
}
/* Grid layout with two columns */
.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(3, 200px);
  gap: 10px;
}

.grid-container > .gallery-item:nth-child(2),
.grid-container > .gallery-item:nth-child(3),
.grid-container > .gallery-item:nth-child(4) {
  grid-column: 2; /* Place the second, third, and fourth items in the second column */
}

.grid-container > .gallery-item:nth-child(1) {
  grid-column: 1; /* First image stays in the first column */
  grid-row: span 3; /* First image takes up all three rows */
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 8px;
}

.gallery-item:hover img {
  transform: scale(1.005);
}

/* Lightbox overlay */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer; /* Cursor indicates clickable area */
}

.lightbox-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto; /* Cursor indicates the content area is not clickable */
}

.lightbox img {
  max-width: 90%;
  max-height: 90%;
}

/* Navigation buttons (Next/Previous) */
.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  border: none;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  user-select: none;
}

.prev-button {
  left: 20px;
}
.remaining-count {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 14px;
}

.next-button {
  right: 20px;
}

/* Close button */
.close-button {
  position: absolute;
  top: 20px;
  right: 40px;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  border: none;
  padding: 10px;
  font-size: 22px;
  cursor: pointer;
}

/* Mobile view: 2 rows layout */
@media screen and (max-width: 768px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
    grid-template-rows: auto auto; /* Two rows: one for the full-width image, one for three images */
    gap: 10px;
  }

  /* Full-width image in the first row */
  .grid-container > .gallery-item:nth-child(1) {
    grid-column: span 3; /* Full width across all three columns */
    grid-row: 1; /* First row */
    width: 100%; /* Ensure the image spans the full width */
  }

  /* 3 images in the second row, each taking one column */
  .grid-container > .gallery-item:nth-child(2),
  .grid-container > .gallery-item:nth-child(3),
  .grid-container > .gallery-item:nth-child(4) {
    grid-column: span 1; /* Each image takes one column */
    grid-row: 2; /* Second row */
    width: 100%; /* Ensure images fit their grid cell */
  }
}

</style>
